import { DEFAULT_REQUEST_HEADER, STORE_HEADER_SERVICES_URL } from "../../common/store-header-services-constants";
export function fetchStorePredictionsService(search, countries, successCallback, errorCallback) {
  lm.fetch({
    url: STORE_HEADER_SERVICES_URL + "/v1/places",
    data: {
      search,
      countries
    },
    method: 'GET',
    credentials: 'same-origin',
    headers: DEFAULT_REQUEST_HEADER,
    success: successCallback,
    error: errorCallback
  });
}