import { EVENT_TYPE } from "../../../storecontextlayer/enums/event-type";
import { customerContextCity, customerContextPostcode, ID_SELECTOR } from "../../customercontextlayer.src";
import { hitEventTms } from "integration-web-core--socle/js/assets/commons/_tms.js";
import { createCustomerContextCookie } from "../../../storecontextlayer/services/customer-context-cookie-service";
import CustomerContext from "../../../storecontextlayer/models/customer-context";
const STORE_CONTEXT_BUTTON = 'customer-context-button';
const STORE_SEARCH_RESULTS = 'store-search-results';
let storeIdCookieName;
let newStoreIdCookieName;
export function initStoreSearchResultData(storeContextLayerBlock) {
  storeIdCookieName = storeContextLayerBlock.dataset.storeidcookiename;
  newStoreIdCookieName = storeContextLayerBlock.dataset.newstoreidcookiename;
}
function initContextButtonTracking() {
  hitEventTms(window, 'cdl_event', JSON.stringify({
    'event_name': 'button.click',
    'event_data': {
      'feature_name': 'header',
      'step_name': 'store choice',
      'funnel_name': 'contextualization layer',
      'button_name': 'selected store'
    }
  }));
}
export function integrateHtmlToStoreSearchBlock(html, storeSearchBlock) {
  const storeSearchResultBlock = storeSearchBlock.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH_RESULTS}"]`);
  storeSearchResultBlock.innerHTML = html;
}
export function applyTrackingAndContextCookieOnStoreSearchBlockWithoutCoordinates(storeSearchBlock) {
  return applyTrackingAndContextCookieOnStoreSearchBlock(storeSearchBlock, "", "");
}
export function applyTrackingAndContextCookieOnStoreSearchBlock(storeSearchBlock, latitude, longitude) {
  const storeContextualizationButtons = storeSearchBlock.querySelector(`[${ID_SELECTOR}="${STORE_CONTEXT_BUTTON}"]`);
  storeContextualizationButtons.addEventListener(EVENT_TYPE.CLICK, event => {
    initContextButtonTracking();
    const storeId = storeSearchBlock.querySelector('.js-form-checkbox:checked').value;
    createCustomerContextCookie(new CustomerContext(storeId, customerContextPostcode, customerContextCity, latitude, longitude), () => {
      window.location.reload();
    });
  });
}