import { EVENT_TYPE } from "../../../../storecontextlayer/enums/event-type";
import { onPredictionSelection } from "../store-context-search";
export function renderStorePredictions(localities, searchPredictionsBlock) {
  const localitiesList = localities.map((locality, index) => {
    const predictionDesc = locality.description;
    const listElemLocality = document.createElement('li');
    const focusPrediction = index === 0 ? ' js-focus-prediction' : '';
    listElemLocality.setAttribute('class', 'm-store-search-predictions__elem' + focusPrediction);
    listElemLocality.setAttribute('tabindex', "0");
    listElemLocality.setAttribute('id', index);
    listElemLocality.innerHTML = predictionDesc;
    listElemLocality.addEventListener(EVENT_TYPE.CLICK, () => onPredictionSelection(locality));
    listElemLocality.addEventListener(EVENT_TYPE.KEYPRESS, function (event) {
      if (event.key === EVENT_TYPE.ENTER) {
        onPredictionSelection(locality);
      }
    }.bind(this));
    return listElemLocality;
  });
  searchPredictionsBlock.replaceChildren(...localitiesList);
  searchPredictionsBlock.style.display = 'block';
}