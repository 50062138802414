const ID_SELECTOR = 'data-selectorid';
const STORE_CONTEXT_LAYER_ERROR = 'store-context-layer-error';
const STORE_SEARCH_ERROR = 'store-search-error';
export function insertErrorMessage(storeSearch, message) {
  const errorBlock = storeSearch.querySelector(`[${ID_SELECTOR}="${STORE_CONTEXT_LAYER_ERROR}"]`);
  errorBlock.innerHTML = message;
  const storeSearchErrorBlock = storeSearch.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH_ERROR}"]`);
  storeSearchErrorBlock.classList.remove('kl-hidden');
  storeSearchErrorBlock.classList.add('kl-visible');
}
export function clearErrorMessage(storeSearch) {
  const storeSearchErrorBlock = storeSearch.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH_ERROR}"]`);
  storeSearchErrorBlock.classList.remove('kl-visible');
  storeSearchErrorBlock.classList.add('kl-hidden');
}