import { EVENT_TYPE } from "../../../storecontextlayer/enums/event-type";
import { geolocateUser } from "./geolocation";
import { onInputNavigation, onPredictionsNavigation } from "./autocomplete/navigation";
import { onInputSearch } from "./autocomplete/search";
import { fetchStorePredictionsService } from "../../../storecontextlayer/services/store-context-service";
import { clearErrorMessage, insertErrorMessage } from "./errors";
import { renderStorePredictions } from "./autocomplete/render";
import { applyTrackingAndContextCookieOnStoreSearchBlock, initStoreSearchResultData, integrateHtmlToStoreSearchBlock } from "../store-search-result/store-search-result";
import { hitEventTms } from "integration-web-core--socle/js/assets/commons/_tms.js";
import { initDecreasedViewListener } from "./decreased-store-search";
import { customerContextCity, customerContextPostcode } from "../../customercontextlayer.src";
const STORE_SEARCH_RESULTS = 'store-search-results';
const STORE_SEARCH = 'store-search';
const STORE_SEARCH_LOADER = 'store-search-loader';
const SEARCH_INPUT_NAME = 'customerCentricSearchInput';
const SEARCH_PREDICTIONS = 'search-predictions';
export const STORE_CONTEXT_LAYER_URL = '/store-header-module/services/customercontextlayer';
const ID_SELECTOR = 'data-selectorid';
const ARIA_EXPANDED = 'aria-expanded';
const storeContextLayerBlock = document.querySelector(`[${ID_SELECTOR}="component-customer-context-layer"]`);
const searchPredictionsBlock = storeContextLayerBlock.querySelector(`[${ID_SELECTOR}="${SEARCH_PREDICTIONS}"]`);
const storeSearchBlock = storeContextLayerBlock.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH}"]`);
const storeSearchLoaderBlock = storeSearchBlock.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH_LOADER}"]`);
const storeSearchInputBlock = storeSearchBlock.querySelector(`input[name="${SEARCH_INPUT_NAME}"]`);
const storeSearchResultBlock = storeSearchBlock.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH_RESULTS}"]`);
const localitiesCountry = storeContextLayerBlock.dataset.localitiescountry;
let timeoutID = 0;
function sendCdlEventOnResultDisplay() {
  hitEventTms(window, 'cdl_event', JSON.stringify({
    'event_name': 'step.display',
    'event_data': {
      'feature_name': 'header',
      'step_name': 'store result',
      'funnel_name': 'contextualization layer'
    }
  }));
}
export function initStoreContextSearch() {
  const geolocateButton = storeContextLayerBlock.querySelector(`[${ID_SELECTOR}="user-locator-button"]`);
  geolocateButton.addEventListener(EVENT_TYPE.CLICK, geolocateUser.bind(this, storeSearchBlock, storeSearchResultBlock));
  storeSearchInputBlock.addEventListener(EVENT_TYPE.INPUT, function (event) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      const input = storeSearchInputBlock.value;
      if (input === 0) {
        searchPredictionsBlock.style.display = 'none';
      } else {
        generateStorePredictions(input);
      }
    }, 250, event);
  }.bind(this));
  const storeSearchForm = storeContextLayerBlock.querySelector(`[${ID_SELECTOR}="store-search-form"]`);
  storeSearchForm.addEventListener(EVENT_TYPE.SUBMIT, event => {
    event.preventDefault();
    return false;
  });
  document.addEventListener(EVENT_TYPE.CLICK, event => {
    if (event.target !== storeSearchInputBlock && event.target !== searchPredictionsBlock) {
      cleanStoreSearchPredictions();
    }
  });
  storeSearchInputBlock.addEventListener(EVENT_TYPE.FOCUS, () => generateStorePredictions(storeSearchInputBlock.value).then(() => {
    const firstSearchElem = searchPredictionsBlock.querySelector(`li:first-child`);
    firstSearchElem && firstSearchElem.classList.add('js-focus-prediction');
  }));
  storeSearchInputBlock.addEventListener(EVENT_TYPE.KEYDOWN, event => onInputNavigation(event, searchPredictionsBlock));
  storeSearchInputBlock.addEventListener(EVENT_TYPE.KEYPRESS, event => onInputSearch(event, storeSearchInputBlock));
  searchPredictionsBlock.addEventListener(EVENT_TYPE.KEYDOWN, event => onPredictionsNavigation(event, searchPredictionsBlock));
  initStoreSearchResultData(storeContextLayerBlock);
  searchPredictionsBlock.style.display = 'none';
}
export function cleanStoreSearchPredictions() {
  if (searchPredictionsBlock.style.display !== 'none') {
    searchPredictionsBlock.style.display = 'none';
    searchPredictionsBlock.innerHTML = '';
    storeSearchInputBlock.setAttribute(ARIA_EXPANDED, 'false');
  }
}
export function onPredictionSelection(locality) {
  cleanStoreSearchPredictions();
  storeSearchInputBlock.value = locality.description;
  if (locality.location) {
    fetchStoresAroundRendering(locality.location.lat, locality.location.lng).then(result => {
      sendCdlEventOnResultDisplay();
      integrateHtmlToStoreSearchBlock(result, storeSearchBlock);
      applyTrackingAndContextCookieOnStoreSearchBlock(storeSearchBlock, locality.location.lat, locality.location.lng);
    });
  }
  if (locality.type === 'locality') {
    customerContextPostcode = locality.postal_codes && locality.postal_codes.length > 0 ? locality.postal_codes[0] : '';
    customerContextCity = locality.name;
  } else if (locality.type === 'postal_code') {
    customerContextPostcode = locality.name;
    customerContextCity = locality.postal_town;
  }
}
function generateStorePredictions(input) {
  return new Promise((resolve, reject) => {
    fetchStorePredictionsService(input, localitiesCountry, predictions => {
      clearErrorMessage(storeSearchBlock);
      predictions = JSON.parse(predictions || '{}');
      if ("localities" in predictions && predictions.localities.length > 0) {
        renderStorePredictions.call(this, predictions.localities, searchPredictionsBlock);
        storeSearchInputBlock.focus();
        storeSearchInputBlock.setAttribute(ARIA_EXPANDED, 'true');
      } else {
        searchPredictionsBlock.style.display = 'none';
        storeSearchInputBlock.setAttribute(ARIA_EXPANDED, 'false');
      }
      resolve();
    }, err => {
      insertErrorMessage(storeSearchBlock, getStoreSearchErrorMessage());
      initDecreasedViewListener();
      reject(err);
    });
  });
}
export function fetchStoresAroundRendering(latitude, longitude, productRef) {
  storeSearchLoaderBlock.style.display = 'block';
  return new Promise((resolve, reject) => {
    fetchStoresAroundRenderingService(latitude, longitude, productRef, resultTemplate => {
      clearErrorMessage(storeSearchBlock);
      resolve(resultTemplate);
    }, err => {
      storeSearchResultBlock.innerHTML = '';
      insertErrorMessage(storeSearchBlock, getStoreSearchErrorMessage());
      initDecreasedViewListener();
      reject(err);
    }, () => {
      storeSearchLoaderBlock.style.display = 'none';
    });
  });
}
export function getStoreSearchErrorMessage() {
  return 'Si è verificato un errore durante il caricamento dei caricatori.';
}
export function getGeolocateErrorMessage(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return 'Non hai voluto condividere la tua posizione.';
    case error.POSITION_UNAVAILABLE:
      return 'Non è stato possibile trovare la posizione dell\'utente.';
    case error.TIMEOUT:
      return 'La localizzazione impiega troppo tempo.';
    default:
      return 'La localizzazione ha riscontrato un errore.';
  }
}
export function fetchStoresAroundRenderingService(latitude, longitude, productRef, successCallback, errorCallback, completeCallback) {
  const data = {
    latitude,
    longitude
  };
  if (productRef) data.productRef = productRef;
  lm.fetch({
    url: STORE_CONTEXT_LAYER_URL + '/store-search-result',
    data: data,
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'text/html',
      'Content-Type': 'text/html; charset=UTF-8',
      'Cache': 'no-cache',
      'Access-Control-Allow-Origin': '*'
    },
    success: successCallback,
    error: errorCallback,
    complete: completeCallback
  });
}