import { KEYCODE } from "../../../../storecontextlayer/enums/key-code";
import { EVENT_TYPE } from "../../../../storecontextlayer/enums/event-type";
import { cleanStoreSearchPredictions } from "../store-context-search";
const MANUAL_FOCUS_CLASS = 'js-focus-prediction';
export function onPredictionsNavigation(event, searchPredictions) {
  const currentFocusId = parseInt(event.target.getAttribute('id'));
  const nextSuggest = currentFocusId + 1;
  const prevSuggest = currentFocusId - 1;
  switch (event.keyCode) {
    case KEYCODE.DOWN_ARROW:
      const downPredictionElem = searchPredictions.querySelector(`li[id="${nextSuggest}"]`) || searchPredictions.querySelector(`li:first-child`);
      const currentPredictionElem = searchPredictions.querySelector(`li[id="${currentFocusId}"]`);
      if (currentPredictionElem.classList.contains(MANUAL_FOCUS_CLASS)) {
        currentPredictionElem.classList.remove(MANUAL_FOCUS_CLASS);
      }
      downPredictionElem.focus();
      event.preventDefault();
      break;
    case KEYCODE.UP_ARROW:
      const upPredictionElem = searchPredictions.querySelector(`li[id="${prevSuggest}"]`) || searchPredictions.querySelector(`li:last-child`);
      upPredictionElem.focus();
      event.preventDefault();
      break;
    case KEYCODE.TAB:
      if (!searchPredictions.querySelector(`li[id="${nextSuggest}"]`)) {
        cleanStoreSearchPredictions();
      }
      break;
  }
}
export function onInputNavigation(event, searchPredictions) {
  const firstElemResultList = searchPredictions.querySelector(`li`);
  if (firstElemResultList) {
    switch (event.keyCode) {
      case KEYCODE.DOWN_ARROW:
        firstElemResultList.classList.remove(MANUAL_FOCUS_CLASS);
        firstElemResultList.focus();
        event.preventDefault();
        break;
      case KEYCODE.ENTER:
        firstElemResultList.focus();
        firstElemResultList.dispatchEvent(new CustomEvent(EVENT_TYPE.CLICK));
        break;
      case KEYCODE.TAB:
        cleanStoreSearchPredictions();
        break;
    }
  }
}