import { getStoreSearchErrorMessage, STORE_CONTEXT_LAYER_URL } from "./store-context-search";
import { insertErrorMessage } from "./errors";
import { EVENT_TYPE } from "../../../storecontextlayer/enums/event-type";
import { applyTrackingAndContextCookieOnStoreSearchBlock, applyTrackingAndContextCookieOnStoreSearchBlockWithoutCoordinates } from "../store-search-result/store-search-result";
const ID_SELECTOR = 'data-selectorid';
const STORE_SEARCH = 'store-search';
const STORE_SEARCH_DECREASED = 'store-search-decreased';
const STORE_SEARCH_DECREASED_BUTTON = 'store-search-decreased-button';
const STORE_SEARCH_DECREASED_LOADER = 'store-search-decreased-loader';
const SEARCH_DECREASED_DROPDOWN_NAME = 'storeSearchRegion';
const STORE_SEARCH_DECREASED_RESULTS = 'store-decreased-results';
const KL_HIDDEN_CLASS = 'kl-hidden';
const KL_VISIBLE_CLASS = 'kl-visible';
const storeContextLayerBlock = document.querySelector(`[${ID_SELECTOR}="component-customer-context-layer"]`);
const storeSearchBlock = storeContextLayerBlock.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH}"]`);
const storeSearchDecreasedButtonBlock = storeSearchBlock.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH_DECREASED_BUTTON}"]`);
const storeSearchDecreasedLoaderBlock = storeSearchBlock.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH_DECREASED_LOADER}"]`);
export function initDecreasedViewListener() {
  storeSearchDecreasedButtonBlock.addEventListener(EVENT_TYPE.CLICK, function () {
    fetchStoresDecreased(handleSuccess, handleError);
  });
}
export function fetchStoresDecreased(successCallback, errorCallback) {
  storeSearchDecreasedLoaderBlock.style.display = 'block';
  lm.fetch({
    url: STORE_CONTEXT_LAYER_URL + '/decreased-view',
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'text/html',
      'Content-Type': 'text/html; charset=UTF-8',
      'Cache': 'no-cache',
      'Access-Control-Allow-Origin': '*'
    },
    success: successCallback,
    error: errorCallback
  });
}
function handleSuccess(response) {
  storeSearchBlock.innerHTML = response;
  // TODO handle differently the coordinats for decreased view (lat, long and postcode will be those from the selected store)
  applyTrackingAndContextCookieOnStoreSearchBlockWithoutCoordinates(storeSearchBlock);
  filterByRegion();
}
function handleError() {
  storeSearchDecreasedButtonBlock.classList.add('kl-hidden');
  insertErrorMessage(storeSearchBlock, getStoreSearchErrorMessage());
}
function filterByRegion() {
  const storeSearchDecreasedBlock = document.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH_DECREASED}"]`);
  const storeSearchDecreasedDropdownBlock = storeSearchDecreasedBlock.querySelector(`select[name="${SEARCH_DECREASED_DROPDOWN_NAME}"]`);
  storeSearchDecreasedDropdownBlock.addEventListener(EVENT_TYPE.CHANGE, handleRegionChange);
}
function handleRegionChange() {
  const selectedRegion = this.value;
  const storeSearchDecreasedBlock = document.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH_DECREASED}"]`);
  const storeSearchDecreasedResultsBlock = storeSearchDecreasedBlock.querySelectorAll(`[${ID_SELECTOR}="${STORE_SEARCH_DECREASED_RESULTS}"] > li`);
  storeSearchDecreasedResultsBlock.forEach(store => {
    const storeRegion = store.getAttribute('data-region');
    handleStoreVisibility(store, storeRegion === selectedRegion);
  });
}
function handleStoreVisibility(store, isVisible) {
  store.classList.remove(isVisible ? KL_HIDDEN_CLASS : KL_VISIBLE_CLASS);
  store.classList.add(isVisible ? KL_VISIBLE_CLASS : KL_HIDDEN_CLASS);
}