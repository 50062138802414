import { insertErrorMessage } from "./errors";
import { fetchStoresAroundRendering, getGeolocateErrorMessage } from "./store-context-search";
import { applyTrackingAndContextCookieOnStoreSearchBlock, integrateHtmlToStoreSearchBlock } from "../store-search-result/store-search-result";
export function geolocateUser(storeSearchBlock, storeSearchResultBlock) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(position => {
      if (position && position.coords) {
        fetchStoresAroundRendering(position.coords.latitude, position.coords.longitude).then(result => {
          integrateHtmlToStoreSearchBlock(result, storeSearchBlock);
          applyTrackingAndContextCookieOnStoreSearchBlock(storeSearchBlock, position.coords.latitude, position.coords.longitude);
        });
      }
    }, positionError => {
      storeSearchResultBlock.innerHTML = '';
      insertErrorMessage(storeSearchBlock, getGeolocateErrorMessage(positionError));
    });
  }
}