import { DEFAULT_REQUEST_HEADER, STORE_HEADER_SERVICES_URL } from "../../common/store-header-services-constants";
export function createCustomerContextCookie(customerContext, completeCallback) {
  let data = {
    'mainStore': customerContext.getMainStore(),
    'latitude': customerContext.getLatitude(),
    'longitude': customerContext.getLongitude()
  };
  if (customerContext.getPostcode()) data.postcode = customerContext.getPostcode();
  if (customerContext.getCity()) data.city = customerContext.getCity();
  lm.fetch({
    url: STORE_HEADER_SERVICES_URL + '/storeheader/customer-context-cookie',
    data,
    method: 'GET',
    credentials: 'same-origin',
    headers: DEFAULT_REQUEST_HEADER,
    complete: completeCallback
  });
}